.hero-style-1 {
	.hero-main-col {
		.hero-headline {
			font-size:24px;
			text-shadow: 1px 1px 0 #303030;
			font-weight:700;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:55px;
			}
		}

		.hero-subline {
			font-size:18px;
			text-shadow: 1px 1px 0 #303030;
			font-family:$heading-font;
			@include media-breakpoint-up(lg) {
				font-size:36px;
			}
		}

		.hero-button {
			margin-top:45px;
		}
	}
}

.rpb-hero .hero-video .autoplay-video {
    transform: translate(-50%, 20%) !important;
}

.cp-service-tabs {
    position: relative;
    #navTabs {
        margin: 0 auto;
        z-index: 1;
        display: flex;
		align-items: center;
        border-bottom: none;
        li.nav-item {
            max-width: 100%;
            display: inline-block;
            font-size: 1.1vw;
			border-bottom:1px solid rgba(0,0,0,0.1)!important;
            &:hover, &:focus {
                border: none;
                opacity: 1;
                background: transparent;
                a.nav-link {
                    opacity: 1;
                    border: none;
                }
				.icon-wrap {
					background: $brand-secondary;
					i,svg {
						color: #fff;
					}
				}
            }
            a.nav-link {
                opacity: 0.7;
                border: none!important;
				padding: 0;
                &.active {
                    background: transparent;
                    opacity: 1;
                    border: none!important;
					.icon-wrap {
						background: $brand-secondary;
						i,svg {
							color: #fff;
						}
					}
                    }
                }
				.icon-wrap {
					height: 110px;
					width: 110px;
					border-left:1px solid rgba(0,0,0,0.1);
					border-right:1px solid rgba(0,0,0,0.1);
					align-items: center;
					justify-content: center;
					margin-right: 2rem;
					transition: all 0.25s;
					font-size: 1.85rem;
				}
            }
			.service-tabs-bg {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				background: rgba(0,7,37,0.3);
			}
        }

    .tab-pane {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
        &:not(.active) {
			display: none;
		}
    }
    .tab-pane-content {
        background: $light;
		padding: 2rem;
		position: absolute;
		bottom: -75px;
		right: 0;
		max-width: 750px;
    }
}

.rpb-simple-content {
    .hc-half-content-image {
        position: relative;
        &:before {
            content: "";
            height: 40%;
            left: 0;
            position: absolute;
            bottom: -12px;
            width:100%;
            z-index: -1;
            background: $brand-secondary;
        }
    }
}

.rpb-second-content-area {
    .hc-half-content-image {
        &:before, &:after {
            display: none;
        }
    }
}

.rpb-logo-slider {
    .logo-banner-single {
        img {
            max-height: 140px;
        }
    }
    .splide__slider {
        .splide__arrows {
            .splide__arrow {
                box-shadow: none!important;
            }
        }
    }
}

.customer-reviews-slider {
    .location-single-review {
        transition: all 0.35s;
    }
    .splide__slider {
        .splide__track {
            margin-left: 3rem!important;
            margin-right: 3rem!important;
                @include media-breakpoint-up(xl) {
                    margin-left: 1rem!important;
                    margin-right: 1rem!important;
                }
            }
    }
    .splide__slider {
        .splide__slide {
            .location-single-review {
                transform: scale(1);
                box-shadow: 0 0 8px rgba(0,0,0,0);
                @include media-breakpoint-up(xl) {
                    transform: scale(0.9);
                    box-shadow: 0 0 24px rgba(0,0,0,0);
                }
            }
        }
        .splide__slide.is-active {
            .location-single-review {
                position: relative;
                z-index: 100;
                box-shadow: 0 0 24px rgba(0,0,0,0.1);
                transform: scale(1);
                @include media-breakpoint-up(xl) {
                    transform: scale(1.05);
                }
            }
        }
    }
}