.rpb-team {
  .team-member-single {
    transition:all 350ms;
    @include media-breakpoint-up(lg) {
      padding:20px;
    }
    &:hover {
      cursor:pointer;
      background:white;
      .team-modal-button {
        background:$brand-primary;
        color:white;
      }
    }
    .team-member-image {
 
      background-size:cover;
      background-position:center center;
      background-repeat:no-repeat;
      max-width:300px;
      min-height:330px;
      @include media-breakpoint-up(lg) {
   
        max-width:300px;
        min-height:330px;
      }
      .team-member-empty-image {
        font-size:48px;
        position: absolute;
        top: 50%;
        opacity:0.5;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        @include media-breakpoint-up(lg) {
          font-size:100px;
        }
      }
    }
    .team-modal-button {
      position:absolute;
      bottom:0;
      right:0;
      border:0;
      border-radius:6px;
      width:65px;
      height:65px;
      background:white;
      color:$brand-primary;
      transition-duration:350ms;
      display:flex;
      align-items:center;
      justify-content:center;
      i,svg {
        font-size:26px;
      }
      &:hover {
        background:$brand-primary;
        color:white;
      }
      &.team-modal-button-noimage {

      }
    }
    .team-member-content {
      p {
        margin-bottom:0.5rem;
        &:last-of-type {
          margin-bottom:0;
        }
      }
    }
  }
}

.team-bio-modal {
  .modal-content {
    background-color:transparent;
  }
  .modal-body {
    display:flex;
    .btn-close {
      position:absolute;
      right:15px;
      top:15px;
    }
    .bio-modal-image {
      background-size:cover;
      background-position:center center;
      background-repeat:no-repeat;
      flex-basis:25%;
    }
    .bio-modal-content {
      background-color:white;
      @include media-breakpoint-up(lg) {
        flex-basis:75%;
      }
      &.modal-content-no-image {
        @include media-breakpoint-up(lg) {
          flex-basis:100%;
        }
      }
      .bio-modal-job {
        color:#555;
      }
      .bio-modal-bio {
        overflow-y:auto;
        max-height:200px;
        @include media-breakpoint-up(lg) {
          max-height:300px;
        }
      }
    }
  }
}


.team-member-content{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: #fff;
  visibility: hidden !important;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.rpb-team .team-member-single .team-member-image:hover .team-member-content{
visibility: visible !important;
opacity:1;
background:rgba(0,0,0,0.4)
}