body {
  background: white;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

/** custom classes */
@include media-breakpoint-down(lg) {
  .background-primary-lg {
    background: $brand-primary!important;
  }
}

.notunder1500 {
  .btn {
    width: 100%;
    border-radius: 0!important;
  }
}

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 66vw;

  @include media-breakpoint-up(xl) {
    min-height: 450px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

img.alignright, img.bodyimgright {
    float: right;
    max-width: 45%;
    margin-bottom: 1rem;
    margin-left: 1rem!important;
    @include media-breakpoint-up(lg) {
        max-width: 35%;
    }
}

img.alignnone, img.alignleft, img.bodyimgleft {
    float: left;
    max-width: 45%;
    margin-bottom: 1rem;
    margin-right: 1rem!important;
    @include media-breakpoint-up(lg) {
        max-width: 35%;
    }
}

.gallery {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 20px;
    .gallery-item {
      .gallery-icon {
        transition: all 0.25s;
        box-shadow: 0 0 8px rgba(0,0,0,0.15);
        img{
          border-radius: 4px;
        }
        &:hover {
          box-shadow: 0 0 14px rgba(0,0,0,0.25);
        }
      }
    }
  }

.yt-wrap  {
    margin-bottom: 1.25rem;
    border:2px solid $gray;
    background: #fff;
    iframe {
        padding: .85rem;
    }
}

.floatCall {
    bottom: 10px;
    left:  10px;
    height: 52px;
    width: 52px;
    padding: 15px 0;
    z-index: 200;
    i, svg {
      font-size: 20px;
      margin-top: -7px;
      @include media-breakpoint-up(lg) {
        margin-top: 0px;
    }
    }
    small {
      font-size: 0.75em;
    }
    @include media-breakpoint-up(lg) {
        bottom: 18px;
        left: 15px;
    }
  }


.theme-switch-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 1rem;
    position: fixed;
    z-index: 99999
}

.theme-switch-wrapper * {
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in
}

.theme-switch-wrapper .theme-switch {
    display: inline-block;
    height: 60px;
    margin-bottom: 0;
    position: relative;
    width: 34px
}

.theme-switch-wrapper .theme-switch input {
    display: none
}

.theme-switch-wrapper .slider {
    background-color: #ccc;
    border-radius: 34px;
    bottom: 0;
    -webkit-box-shadow: 0 0 20px -10px #333840;
    box-shadow: 0 0 20px -10px #333840;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.theme-switch-wrapper .slider:after,
.theme-switch-wrapper .slider:before {
    border-radius: 100%;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    -webkit-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    transition: all .3s ease-in
}

.theme-switch-wrapper .slider:before {
    background-color: #fff;
    -webkit-transform: translateY(-26px);
    -o-transform: translateY(-26px);
    transform: translateY(-26px);
    width: 26px
}

.theme-switch-wrapper .slider:after {
    background-color: #ccc;
    -webkit-transform: translateY(-26px) scale(0);
    -o-transform: translateY(-26px) scale(0);
    transform: translateY(-26px) scale(0);
    -webkit-transform-origin: center left;
    -o-transform-origin: center left;
    transform-origin: center left;
    width: 16px;
    z-index: 1
}

.theme-switch-wrapper input:checked+.slider {
    background-color: #fff;
}

.theme-switch-wrapper input:checked+.slider:before {
    background-color: #333840;
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0)
}

.theme-switch-wrapper input:checked+.slider:after {
    background-color: #ccc;
    -webkit-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1)
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
    margin-top: -85px;
    @include media-breakpoint-up(lg) {
        margin-top: -100px;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  tr:nth-of-type(odd) {
    background: #eee;
  }
  th {
    background: #333;
    color: white;
    font-weight: bold;
  }
  td, th {
    padding: 6px;
    text-align: left;
  }

  table {
    img {
        margin-top: 1rem;
    }
    tr {
        td {
            text-transform: uppercase;
            color: $brand-secondary;
            font-size: 1.25rem;
            font-family: $light-weight;
            letter-spacing:6px;
        }
    }
  }

// Images

img {
  max-width: 100%;
}

// Util
.img-invert-white {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index:2;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 200px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}

.h2-content-section h2{
  color:white !important;
}

.financing-icon-lg {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 10px;
  max-width: 200px;
  opacity: 0.22;
  position: absolute;
  top: 10px;
  z-index: 0;
  min-width: 175px;

}

.logo-white-corner {
  left: 10px;
  opacity: .2;
  position: absolute;
  top: -50px;
  z-index: 0;
}

.service-ul li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media (min-width: 1200px) {
  .h4, .service-ul li, h4 {
      font-size: 2rem;
  }
}

  @media (min-width: 992px) {
    .h4, .service-ul li, h4 {
        font-size: 1.5rem;
    }
}

.comment-form p, .mb-3, .search-form label, .service-ul li, .servicepage-ul li {
  margin-bottom: 1.5rem !important;
}

.me-4, .service-ul li, .servicepage-ul li {
  margin-right: 2.25rem !important;
}