.site-footer {
    img.footer-logo {
        max-width: 75%;
    }
    .comfort-plus-vehicle-wrap {
        .image-border {
            height: 3px;
            background: rgba(0,0,0,0.1);
            width: 100%;
            position: absolute;
            top: 40%;
        }
    }
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:7.5px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: $dark;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}
	.social-icon {
		background: $brand-secondary;
        align-items: center;
        border-radius: 4px;
        display: inline-flex;
        height: 1.5em;
        justify-content: center;
        width: 1.5em;
		transform:scale(1);
		a,svg {
			color:#fff;
			font-size:.8em;
		}
		&:hover {
			background: rgba(0,0,0,.1);
			transform:scale(1.05);
			svg {
				color:$brand-primary;
			}
		}
	}
	.gform_wrapper {
		.gform_description {
			margin-bottom:1rem;
			display:block;
		}
		.gfield_required_text {
			color:white;
		}
	}
	.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
		background:white;
		border:4px solid white;
		color:$brand-primary;
		&:hover {
			background:lighten($brand-primary,15%);
			border:4px solid lighten($brand-primary,15%);
			color:white;
		}
	}
}

.footer-main {
	p {
		margin-bottom:15px;
	}
}

.footer-dark {
	ul {
		li {
			a {
				color:white;
				&:hover {
					color:white;
					opacity:0.8;
				}
			}
		}
	}
}

.copyright-dark {
	background:darken($dark, 10%);
	a {
		color:white;
		&:hover {
			color:white;
			opacity:0.8;
		}
	}
}
